<template>
  <section
    v-if="userInfo && userInfo.tags"
    class="flex flex-col w-screen min-h-screen px-4 bg-gray-100 page-workspace-dashboard"
  >
    <template v-for="group of workspaceMenuitems">
      <h3
        v-if="!group.tags || group.tags.filter(x => userInfo.tags.includes(x)).length > 0"
        :key="`header-${group.id}`"
        class="mt-6 mb-2 font-normal text-gray-600"
      >
        {{ group.name }}
      </h3>
      <van-grid
        v-if="!group.tags || group.tags.filter(x => userInfo.tags.includes(x)).length > 0"
        :key="`content-${group.id}`"
        column-num="3"
        square
      >
        <template v-for="item in group.children">
          <van-grid-item
            v-if="item.disabled"
            :key="item.id"
            :icon="item.icon"
            :text="item.title"
            class="disabled-grid-item"
            @click="onDevelopment"
          >
            <template #icon>
              <div class="mb-2 text-2xl">
                <i
                  class="fal"
                  :class="item.icon"
                />
              </div>
            </template>
            <template #text>
              <div
                :inner-html.prop="item.title | htmlXss"
                class="text-sm"
              />
            </template>
          </van-grid-item>
          <van-grid-item
            v-else
            :key="item.name"
            :to="item.route"
          >
            <template #icon>
              <div class="mb-2 text-2xl">
                <i
                  class="fal"
                  :class="item.icon"
                />
              </div>
            </template>
            <template #text>
              <div
                :inner-html.prop="item.title | htmlXss"
                class="text-sm"
              />
            </template>
          </van-grid-item>
        </template>
      </van-grid>
    </template>
  </section>
</template>
<script>
import share from '@/mixins/share'
import { mapState, mapGetters } from 'vuex'
export default {
  name: 'Workspace',
  mixins: [share],
  computed: {
    ...mapState('Common/Menu', ['workspaceMenuitems']),
    ...mapGetters('Common/Credential', ['userInfo'])
  },
  mounted() {
    this.disableShareMenu()
    // eslint-disable-next-line no-console
    console.log(this.userInfo)
  },
  methods: {
    onDevelopment() {
      // eslint-disable-next-line no-console
      console.warn('onDevelopment')
      this.$toast('开发中...')
    }
  }
}
</script>
<style lang="scss" scoped>
.disabled-grid-item {
  color: #ccc;
}
</style>